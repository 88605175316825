<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <div class="field m-b-none">
        <heading size="4">
          {{ $t('components.clippings.add_instagram_manual.add_link_or_screenshot') }}
        </heading>
        <div class="control is-flex is-aligned-end">
          <el-switch
            v-model="form.useUrl"
            :active-text="$t('components.clippings.add_instagram_manual.instagram_url')"
            :inactive-text="$t('components.clippings.add_instagram_manual.instastories_screenshots')"
            @change="validator.form.$reset()"
          />
        </div>
      </div>
    </div>
    <div
      v-if="form.useUrl"
      class="column is-6-desktop"
    >
      <form-field
        :validator="validator.form.url"
        label="URL"
      >
        <input
          v-model="form.url"
          class="input"
          type="text"
          name="url"
          placeholder="https://www.instagram.com/p/BJ-tJ9YBaod/"
          data-testid="ManualClippingURLInput"
          @input="validator.form.url.$touch()"
        >
      </form-field>
    </div>
    <div
      v-if="!form.useUrl"
      class="column"
    >
      <form-field
        :validator="validator.form.files"
        :label="$t('components.clippings.add_instagram_manual.instastories_screenshots') "
      >
        <el-upload
          ref="uploader"
          v-bind="fileUpload.options"
          :file-list="form.files"
          :http-request="uploadFile"
          :on-success="fileUploadSuccess"
          :on-error="fileUploadError"
          :before-remove="fileUploadBeforeRemove"
          :before-upload="fileBeforeUpload"
          :on-remove="handleRemoveFile"
          class="FilesPickerUpload m-b-m"
        >
          <i class="el-icon-upload" />
          <div class="el-upload__text" v-html="$t('forms.drop_files_here')" />
          <div class="is-size-7 has-text-grey m-t-m">
            {{ $t('forms.accepted_file_types') }} {{ fileUpload.options.accept }}
          </div>
        </el-upload>
      </form-field>
    </div>
  </div>
</template>

<script>
import ClippingTabWithFileUploadMixin from '@/components/clippings/tabs/ClippingTabWithFileUploadMixin'

export default {
  mixins: [ClippingTabWithFileUploadMixin],
  props: {
    validator: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    }
  }
}
</script>
