<template>
  <div class="columns">
    <div class="column is-6-desktop">
      <form-field
        :validator="validator.form.url"
        label="URL"
      >
        <input
          v-model="form.url"
          class="input"
          type="text"
          name="url"
          placeholder="https://www.youtube.com/watch?v=CStXVSkR1Y4"
          data-testid="ManualClippingURLInput"
          @input="validator.form.url.$touch()"
        >
      </form-field>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    validator: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import "~utils";
</style>
