<template>
  <div class="columns">
    <div class="column">
      <form-field :validator="validator.form.files" attribute="files">
        <el-upload
          ref="uploader"
          v-bind="fileUpload.options"
          :http-request="uploadFile"
          :on-success="fileUploadSuccess"
          :on-error="fileUploadError"
          :before-remove="fileUploadBeforeRemove"
          :on-remove="handleRemoveFile"
          :before-upload="fileBeforeUpload"
          class="FilesPickerUpload m-b-m"
          multiple
        >
          <i class="el-icon-upload" />
          <div class="el-upload__text" v-html="$t('forms.drop_files_here')" />
          <div class="is-size-7 has-text-grey m-t-m">
            {{ $t('forms.accepted_file_types') }} {{ fileUpload.options.accept }}
          </div>
        </el-upload>
      </form-field>
    </div>
  </div>
</template>

<script>
import ClippingTabWithFileUploadMixin from '@/components/clippings/tabs/ClippingTabWithFileUploadMixin'

export default {
  mixins: [ClippingTabWithFileUploadMixin],
  props: {
    validator: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      fileUpload: {
        options: {
          accept: 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/jpeg, image/png'

        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "~utils";
</style>
