import { mapGetters } from 'vuex'

/**
 * Mixin that allows for Clippings with file upload (Print and Instastory) to reuse logic.
 * @module ClippingTabWithFileUploadMixin
 */
export default {
  props: {
    loading: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      /**
       * Configuration object passed to a ElUpload component
       */
      fileUpload: {
        options: {
          accept: 'image/jpeg, image/png',
          drag: true,
          'list-type': 'picture',
          action: '/clippings/upload-files',
          headers: {
            Authorization: `Bearer ${this.authToken}`
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(['authToken'])
  },
  methods: {
    uploadFile (options) {
      const formData = new FormData()
      formData.append('files[]', options.file)

      return this.$api.post(options.action, formData, {
        onUploadProgress (e) {
          if (e.total > 0) {
            e.percent = Math.floor((e.loaded * 100) / e.total)
          }
          options.onProgress(e)
        }
      })
    },
    /**
     * Passed to ElUpload as a callback to a successful file upload
     * @param {Object} response - response from API
     * @param {Object} file - the file in the ElUpload's local state
     * @param {Array} fileList - the full list of files in ElUpload
     */
    fileUploadSuccess (response, file, fileList) {
      // if we did not upload any files, there was an issue with the file
      if (response.data.data.files.length === 0) {
        // find the file that this process was connected to
        const fileIndex = fileList.findIndex(currentFile => file.uid === currentFile.uid)
        // remove it from the list of files directly as we dont have access to the components inner state, and api returned 200 so it thinks it was a success.
        if (fileIndex !== -1) {
          fileList.splice(fileIndex, 1)
        }
        this.$notify.error({
          message: this.$t('errors.clipping_file_not_uploaded'),
          duration: 5000
        })
        this.removeFileLoading(file.uid)
        return
      }
      this.removeFileLoading(file.uid)
      response.data.data.files.forEach(file => {
        // push the just uploaded files to the list of form files.
        this.form.files.push(file)
      })
    },
    /**
     * Passed to ElUpload as a callback to a bad file upload
     * @param {Object} response - response from API
     * @param {Object} file - the file that errored in the ElUpload's local state
     */
    fileUploadError (response, file) {
      this.$displayRequestError(response)
      this.removeFileLoading(file.uid)
    },
    /**
     * Callback before removing an item from ElUpload
     * @param {Object} file - the file that is to be removed form ElUpload's local state
     * @return {Promise<*>}
     */
    fileUploadBeforeRemove (file) {
      if (!this.isUnder30M(file)) {
        return
      }
      return this.$confirm(this.$t('warnings.confirm_clipping_remove_file'))
    },
    /**
     * Callback to handle removing a file
     * @param {Object} file - the file that is to be removed
     * @param {Array} filelist - the full list of files in ElUpload
     */
    handleRemoveFile (file, filelist) {
      // first file in the axios response when uploading
      const fileIndex = this.$safeGet(file, 'response.data.data.files[0].sha1') || this.$safeGet(file, 'sha1')
      // bail if file has no reference to its response
      if (!fileIndex) return
      const index = this.form.files.findIndex(f => f.sha1 === fileIndex)

      if (index !== -1) {
        this.form.files.splice(index, 1)
      }
    },
    fileBeforeUpload (file) {
      if (!this.isUnder30M(file)) {
        this.$displayRequestError({ message: this.$t('errors.file_exceeds_permitted_size', { size: 30 }) })
        return false
      }
      this.addFileLoading(file.uid)
    },
    addFileLoading (uid) {
      this.$emit('update:loading', this.loading.concat([uid]))
    },
    removeFileLoading (uid) {
      let index = this.loading.indexOf(uid)
      let leftItems = this.loading.slice(0)
      if (index !== -1) {
        leftItems.splice(index, 1)
      }
      this.$emit('update:loading', leftItems)
    },
    isUnder30M (file) {
      return file.size / 1024 / 1024 < 30
    }
  }
}
