<template>
  <page-section
    v-loading="form.isLoading"
    :element-loading-text="$t('general.fetching')"
    :page-title="$t('pages.coverage.clipping_create.sidebar_title')"
  >
    <template #tooltip-content>
      <p>
        {{ $t('pages.coverage.clipping_create.sidebar_description_1') }}
      </p>
      <p class="m-t-m m-b-s">
        1. {{ $t('pages.coverage.clipping_create.sidebar_steps_1') }}
      </p>
      <p>{{ $t('pages.coverage.clipping_create.sidebar_steps_1_desc') }}</p>
      <p class="m-t-m m-b-s">
        2. {{ $t('pages.coverage.clipping_create.sidebar_steps_2') }}
      </p>
      <p>{{ $t(sidebarDescription) }}</p>
    </template>
    <div class="Add-clipping__new">
      <div class="columns">
        <div class="column">
          <div
            v-loading="isLoading"
            class="Tabs"
          >
            <div
              v-for="media in allowedMedia"
              :key="media.id"
              :class="[{'is-selected': form.type === media.id}, 'List-media--' + media.id]"
              class="List-media column is-narrow has-text-centered"
              @click="handleSelectMedia(media)"
            >
              <div class="List-media__icon icon is-large is-round">
                <el-tooltip
                  :content="$t('pages.coverage.clipping_create.media_requires_auth')"
                  effect="dark"
                  placement="top-start"
                >
                  <div
                    v-if="requiresFacebookToken(media)"
                    class="List-media__token-warning icon is-round has-background-danger"
                  >
                    !
                  </div>
                </el-tooltip>
                <clipping-type-icon :type="media.id" />
              </div>
              <div>{{ media.name }}</div>
            </div>
          </div>

          <component
            :is="`add-${form.type}-manual`"
            :validator="$v"
            :form.sync="form"
            :loading.sync="itemsBeingUploaded"
          />

          <div class="has-text-right">
            <v-button
              :loading="itemsBeingUploaded.length > 0"
              class="Clipping--next is-primary m-t-l"
              @click="submit"
            >
              <span>
                {{ $t('general.next') }}
              </span>
              <v-icon type="caret-right" />
            </v-button>
          </div>
        </div>
      </div>
    </div>
  </page-section>
</template>

<script>
import Form from '@/services/forms/Form'
import { clippingUrl } from '@hypefactors/shared/js/utils/validation'
import { url, requiredIf, minLength } from 'vuelidate/lib/validators'
import AddTvManual from '@/components/clippings/tabs/AddTvManual'

import AddWebManual from '@/components/clippings/tabs/AddWebManual'
import AddPrintManual from '@/components/clippings/tabs/AddPrintManual'
import AddRadioManual from '@/components/clippings/tabs/AddRadioManual'
import AddForumManual from '@/components/clippings/tabs/AddForumManual'
import AddTwitterManual from '@/components/clippings/tabs/AddTwitterManual'
import AddYoutubeManual from '@/components/clippings/tabs/AddYoutubeManual'
import AddFacebookManual from '@/components/clippings/tabs/AddFacebookManual'
import AddLinkedinManual from '@/components/clippings/tabs/AddLinkedinManual'
import AddInstagramManual from '@/components/clippings/tabs/AddInstagramManual'

import SocialTokenManagerMixin from '@/components/social/SocialTokenManagerMixin'
import { MANUALLY_UNCLIPPABLE_MEDIA_TYPES } from '@hypefactors/shared/js/constants/mediaTypes'

const newClippingForm = () => new Form({
  type: { value: 'web' },
  url: {
    value: '',
    rules: {
      required: requiredIf(function (form) {
        if (this.isInstastory) return false
        return !this.isAFileType
      }),
      url,
      clippingUrl
    }
  },
  files: {
    value: [],
    rules: {
      required: requiredIf(function (form) {
        if (this.isInstastory) return true
        return this.isAFileType
      }),
      minLength: minLength(1)
    }
  },
  brand: { value: null },
  useUrl: { value: true }
})

export default {
  components: {
    AddPrintManual,
    AddWebManual,
    AddTvManual,
    AddRadioManual,
    AddFacebookManual,
    AddInstagramManual,
    AddLinkedinManual,
    AddTwitterManual,
    AddYoutubeManual,
    AddForumManual
  },

  mixins: [SocialTokenManagerMixin],

  data: () => ({
    form: newClippingForm(),
    mediasToFollow: [],
    isLoading: false,
    itemsBeingUploaded: []
  }),

  computed: {
    /**
     * Temporarily filter out review from manual Clipping creation
     * @return {object[]}
     */
    allowedMedia () {
      return this.mediasToFollow.filter(m => !MANUALLY_UNCLIPPABLE_MEDIA_TYPES.includes(m.id))
    },

    /**
     * Returns a dynamic sidebar description based on the chosen type
     * @return {string}
     */
    sidebarDescription () {
      return `pages.coverage.clipping_create.sidebar_steps_${this.form.type}`
    },

    isInstastory () {
      return this.form.type === 'instagram' && !this.form.useUrl
    },

    isAFileType () {
      return ['print', 'tv', 'radio'].includes(this.form.type)
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  mounted () {
    // reset the clipping
    this.$store.dispatch('clippings/add/reset')
    this.fetchMediaTypes()
  },

  methods: {
    fetchMediaTypes () {
      this.isLoading = true
      this.$api.get('clippings/media-types')
        .then(response => {
          this.isLoading = false
          this.mediasToFollow = response.data.data
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
        })
    },

    submit () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return

      this.form.setPayloadTransformer(payload => {
        let strippedPayload = {
          type: payload.type,
          action: 'manual',
          brand: this.activeBrandId
        }
        if (this.isInstastory) {
          // just for Instastory, augment the action
          strippedPayload.action = 'instastory'
        }
        if (this.isInstastory || this.isAFileType) {
          strippedPayload.files = payload.files.map(file => ({
            url: file.url,
            sha1: file.sha1
          }))
        } else {
          strippedPayload.url = payload.url
        }
        return strippedPayload
      })

      this.$store.dispatch('clippings/add/prepareClippingData', this.form)
        .then(() => {
          this.$router.push({ name: 'performance.coverage.clipping.create' })
        })
        .catch(err => {
          this.$displayRequestError(err, this.$t('errors.error'))
        })
    },

    requiresFacebookToken (media) {
      // TODO: facebook only for now?
      return media.id === 'facebook' && this.requiresToken(media) // from mixin
    },

    selectMedia (media) {
      this.form.type = media.id
    },

    async handleSelectMedia (media) {
      if (!this.requiresFacebookToken(media)) {
        return this.selectMedia(media)
      }

      try {
        await this.$confirm(this.$t('pages.coverage.clipping_create.media_requires_auth'), {
          type: 'warning'
        })
        await this.requestSocialToken(media.id)
        this.selectMedia(media)
      } catch (err) {
        if (err !== 'cancel') {
          console.error(err)
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.Add-clipping__new {
  .Tabs .label {
    display: flex;
  }

  .Tabs {
    border-bottom: $hf__border--base;
    margin-bottom: $margin-large;
    min-height: 80px;
  }

  .Tabs > div {
    &:first-of-type {
      padding-left: 0;
    }
  }

  .el-upload {
    margin: auto;
    display: block;

    .el-upload-dragger {
      width: 100%;
      display: inline-block;
    }
  }

  .el-upload-list {
    .el-icon-close-tip {
      display: none !important;
    }

    .el-upload-list__item-name {
      cursor: context-menu !important;

      &:hover {
        color: inherit
      }
    }
  }

  .FormField.has-error .FilesPickerUpload .el-upload-dragger {
    border-color: $danger;
    border-width: 2px;
  }

  .List {
    &-media {
      cursor: pointer;

      &__icon {
        background: $hf__color-gray-darker;
        color: $white;
        transition: 0.1s ease-in;
        position: relative;
      }

      &__token-warning {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(25%, -25%);
      }

      &.is-selected {
        color: $hf__color-primary;

        .List-media__icon {
          background: $hf__color-primary;
        }
      }

      &:hover {
        color: $hf__color-primary--alternative;

        .List-media__icon {
          background: $hf__color-primary--alternative;
        }
      }
    }
  }
}
</style>
